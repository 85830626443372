import React from 'react'

import '../styles/styles.scss'

import Navbar from './navbar'

const Header = ({ siteTitle }) => (
  <React.Fragment>
    <Navbar />
  </React.Fragment>
)

export default Header

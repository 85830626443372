import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

export default () => (
  <StaticQuery
    query={graphql`
      query headingQuery {
        site {
          siteMetadata {
            title
            author
            description
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => (
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta name="description" content={siteMetadata.description} />
        {/* <meta name="keywords" content={siteMetadata.keywords} /> */}
        <title>{siteMetadata.title}</title>
        <html lang="en" />
        {/* Google / Search Engine Meta Tags */}
        <meta itemprop="name" content={siteMetadata.author} /> />
        <meta
          itemprop="description"
          content={siteMetadata.description}
        />
        {/* <meta itemprop="image" content={siteMetadata.imageUrl} /> /> */}
        {/* https://fonts.googleapis.com/css2?family=Damion&family=Inter:wght@300;500;600;700&display=swap */}
        <link rel="stylesheet" href="https://use.typekit.net/xty2hdn.css"/>
      </Helmet>
    )}
  />
)

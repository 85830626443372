import React from 'react'

import '../styles/styles.scss'
import Helmet from './helmet';
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => (
  <React.Fragment>
    <Helmet />
    <Header />
    {children}
    <Footer />
  </React.Fragment>
)

export default Layout

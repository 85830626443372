import React, { useRef } from 'react'

const MailChimpFormEmbedded = () => {
  const formElRef = useRef(null)
  return (
    <div id="mc_embed_signup" ref={formElRef}>
      <form
        action="https://maskofwellness.us4.list-manage.com/subscribe/post?u=66a2d815c0886c682e4afc276&amp;id=3bbe8a0f84"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL">Email Address </label>
            <input
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              defaultValue=""
            />
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: 'none' }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: 'none' }}
            ></div>
          </div>
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_66a2d815c0886c682e4afc276_3bbe8a0f84"
              tabIndex="-1"
              defaultValue=""
            />
          </div>
          <div className="clear">
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              onClick={() => {
                const form = formElRef.current.querySelector('form')
                var isValidForm = form.checkValidity()
                if (!isValidForm) return
              }}
              className="button"
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default MailChimpFormEmbedded

import React from 'react'
// import { FaTwitter, FaGithub, FaMedium } from 'react-icons/fa'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/styles.scss'
import MailchimpFormFooter from './mailchimpFormFooter'

import footerLogo from '../images/mask-logo-footer.svg'

import socialInstagram from '../images/social-instagram.svg'
import socialTwitter from '../images/social-twitter.svg'
import socialFacebook from '../images/social-facebook.svg'
import socialYouTube from '../images/social-youtube.svg'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `}
    render={data => (
      <footer className="footer center">
        <div className="level footer-level-branding">
          <div className="level-item left-align-level">
            <img className="footer-mow-logo" src={footerLogo} alt="Mask of Wellness Logo" />
          </div>
        </div>

        <div className="level footer-level-nav">
          <div className="level-item left-align-level">
            <h6>Overview</h6>
            <ul>
              <li><Link to="/gallery/">Gallery</Link></li>
              <li><Link to="/faq/">FAQ</Link></li>
              <li><Link to="/contact-us/">Contact</Link></li>
            </ul>
          </div>
          <div className="level-item left-align-level">
            <h6>Legal</h6>
            <ul>
              <li><Link to="/accessibility-statement/">Accessibility</Link></li>
              <li><Link to="/terms-and-conditions/">Terms & Conditions</Link></li>
              <li> <Link to="/privacy-policy/">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="level-item right-align-level">
            <h6>Newsletter</h6>
            <p>Sign up to learn about upcoming directory feature updates.</p>
            <MailchimpFormFooter />
          </div>
        </div>

        <div className="level footer-level-legal">
          <div className="level-item left-align-level">
            <p>&copy;Mask of Wellness, LLC</p>
          </div>
          <div className="level-item right-align-level">
            <a
              href="https://www.facebook.com/covidconsciousbusinesses"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
            >
              <img src={socialFacebook} alt="Facebook logo" />
            </a>
            <a
              href="https://www.instagram.com/covidconsciousbusinesses/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
            >
              <img src={socialInstagram} alt="Instagram logo" />
            </a>
            <a
              href="https://twitter.com/covidconscious"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
            >
              <img src={socialTwitter} alt="Twitter logo" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCnYymJ2fA1lemx5HziOuhUA"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
            >
              <img src={socialYouTube} alt="YouTube logo" />
            </a>
          </div>
        </div>

      </footer>
    )}
  />
)

export default Footer
